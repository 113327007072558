
import Vue from 'vue';
import { Inject, Watch, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';
import { TMedia } from '~/plugins/layoutable/media';

import { ESuggestBlocks, TUnionInterfaceBlocks } from '../models';

import SearchSuggestionsBlock from './search-suggestions-block.vue';

const NO_SCROLL_CLASS = 'no-scroll-menu';

@Component({
  components: {
    ButtonUi,
    IconUi,
    SearchSuggestionsBlock,
  },

  directives: {
    ClickOutside,
  },
})
export default class SearchSuggestions extends Vue {
  @Inject('media') media!: TMedia;
  $store!: any;
  $route!: any;
  $router!: any;
  searchQuery = '';
  isSuggestVisible = false;
  isModalOpened = false;
  isClosing = false;
  openAnimationDelay = 1;
  closeAnimationDelay = 1;
  selectedSuggestion: TUnionInterfaceBlocks | null = null;
  hasAnySuggests = false;
  isArrow = true;
  isCancel = false;

  suggestFocusReset(): void {
    this.isSuggestVisible = true;

    if (this.media.isMobileOrTablet) {
      this.openMobileSuggestions();
      this.$emit('close-menu');
      // document.body.classList.remove(NO_SCROLL_CLASS);
    }
  }

  openMobileSuggestions() {
    this.isArrow = false;
    this.isCancel = true;
    if (this.$route.name === 'search') {
      this.isCancel = true;
    }
    if (!this.media.isMobileOrTablet) return;
    setTimeout(() => {
      this.isModalOpened = true;
    }, this.openAnimationDelay);
    document.body.classList.add(NO_SCROLL_CLASS);
  }

  closeMobileSuggestions() {
    this.isCancel = false;
    if (!this.media.isMobileOrTablet) return;

    this.isClosing = true;
    this.isModalOpened = false;
    document.body.classList.remove(NO_SCROLL_CLASS);
    setTimeout(() => {
      this.isClosing = false;
      this.isSuggestVisible = false;
    }, this.closeAnimationDelay);
    this.selectedSuggestion = null;
  }

  closeDesktopSuggestions() {
    if (!this.media.isMobileOrTablet) {
      this.isSuggestVisible = false;
      this.selectedSuggestion = null;
    }
  }

  blur() {
    if (this.$refs.input) {
      const input = this.$refs.input as HTMLElement;

      input.blur();
    }
  }

  onSuggestSelect(value: TUnionInterfaceBlocks): void {
    switch (value.type) {
      case ESuggestBlocks.Text:
      case ESuggestBlocks.Popular:
      case ESuggestBlocks.Cache: {
        this.searchQuery = value.title;
        this.searchText();
        break;
      }
    }

    this.closeDesktopSuggestions();
    this.closeMobileSuggestions();
    this.blur();
  }

  cleanInputField() {
    this.searchQuery = '';
  }

  keyUp(): void {
    const blockCreatorElement = this.$refs['suggestionsBlockCreator'] as any;

    if (!blockCreatorElement) return;

    blockCreatorElement.keyUp();
  }

  keyDown(): void {
    const blockCreatorElement = this.$refs['suggestionsBlockCreator'] as any;

    if (!blockCreatorElement) return;

    blockCreatorElement.keyDown();
  }

  search(): void {
    if (!this.selectedSuggestion) {
      this.searchText();
    } else {
      this.onSuggestSelect(this.selectedSuggestion);
    }
  }

  searchText(): void {
    if (this.searchQuery) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$store.commit('user/pushSearchHistoryValue', this.searchQuery.trim());
      const query = this.searchQuery.substr(0, 100);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$router.push({ path: '/search', query: { query } });
    }

    this.closeDesktopSuggestions();
    this.closeMobileSuggestions();
    this.blur();
  }

  @Watch('searchQuery')
  searchQueryWatcher(curr) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (curr === this.$route.query.query || curr === '') return;

    this.suggestFocusReset();
  }

  // @Watch('isSuggestVisible')
  // isSuggestVisibleWatcher(curr) {
  //   const headerEL = document.querySelector('.ds-header-container') as HTMLElement;
  //   headerEL.style.zIndex = curr ? '3' : 'auto'; // Чтобы поисковые фильтры не перекрывали поисковые подсказки
  // }

  @Watch('$route', { deep: true, immediate: true })
  routeWatcher(newRoute, oldRoute) {
    this.isSuggestVisible = false;
    if (newRoute.name === 'home' || newRoute.name === 'search') {
      this.isArrow = false;
      this.isCancel = false;
    }

    const { query: searchQuery = '' } = newRoute.query;
    if (searchQuery) {
      this.searchQuery = searchQuery;
    } else if (oldRoute && newRoute.path !== oldRoute.path) {
      this.cleanInputField();
    }
  }
}
